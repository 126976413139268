import Cropper from "cropperjs"
import "cropperjs/dist/cropper.css"

export default () => ({
  cropper: null,
  zoom: 0.2,
  aspectRatio: 4 / 3,
  croppedImage: null,

  b64toBlob(b64Data, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)
      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
  },

  convertBase64ToImage(base64) {
    const block = base64.split(";")
    const contentType = block[0].split(":")[1]
    const realData = block[1].split(",")[1]
    const blob = this.b64toBlob(realData, contentType)
    const url = URL.createObjectURL(blob)

    return url
  },

  initCropper() {
    if (this.cropper) {
      this.cropper.destroy()
    }

    if (!this.imageToCrop || !this.$refs.image) {
      return
    }

    this.$refs.image.src = this.convertBase64ToImage(this.imageToCrop)

    this.cropper = new Cropper(this.$refs.image, {
      aspectRatio: this.aspectRatio,
      viewMode: 1,
      autoCropArea: 0.8,
      cropBoxResizable: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      guides: false, // hide guides
      background: false, // hide background
      center: false, // hide center indicator
    })
  },

  save() {
    const canvas = this.cropper.getCroppedCanvas({
      minWidth: 1200,
      imageSmoothingQuality: "high",
      fillColor: "white",
    })

    if (!canvas) {
      this.currentStep = null
      return
    }

    const { name, type } = this.projectDetails.coverImage

    canvas.toBlob(
      blob => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          this.croppedImage = {
            name,
            type,
            dataUrl: base64data,
            url: this.convertBase64ToImage(base64data),
          }
        }
      },
      type,
      0.9,
    )
  },

  cancel() {
    this.$refs.image.src = ""
    this.$dispatch("cancel-cropping")
  },

  zoomOut() {
    if (this.zoom > 0) {
      this.zoom = parseFloat(this.zoom) - 0.1
    }
  },

  zoomIn() {
    const newZoom = parseFloat(this.zoom) + 0.1
    const roundedZoom = Math.round(newZoom * 10) / 10
    if (roundedZoom <= 1) {
      this.zoom = roundedZoom
    }
  },

  handleRotate() {
    this.cropper.rotate(90)
  },

  init() {
    this.initCropper()

    this.$watch("croppedImage", image => {
      this.$dispatch("update-image", image)
    })
    this.$watch("zoom", (value, oldValue) => {
      value = parseFloat(value)
      oldValue = parseFloat(oldValue)

      const difference = value - oldValue
      const roundedDifference = Math.round(difference * 10) / 10

      this.cropper.zoom(roundedDifference)
    })
  },
})
