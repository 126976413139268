import EmblaCarousel from 'embla-carousel'

export default (urls: string[]) => ({
  embla: null,
  parentEl: null,
  handleClick(index) {
    this.embla = null
    const clonedNode = this.$refs.galleryModal.cloneNode(true);
    this.$store.modal.open(clonedNode, {
      selectedIndex: index + 1,
      activeUrl: urls[index],
    });

    const rootNode = clonedNode.querySelector('.embla');
    if (!rootNode) return;

    const viewportNode = rootNode.querySelector('.embla__viewport');
    const prevButtonNode = rootNode.querySelector('.embla__prev');
    const nextButtonNode = rootNode.querySelector('.embla__next');

    this.prevButtonNode = prevButtonNode;
    this.nextButtonNode = nextButtonNode;

    this.embla = EmblaCarousel(viewportNode as HTMLElement, { loop: true });

    prevButtonNode?.addEventListener('click', this.embla?.scrollPrev, false);
    nextButtonNode?.addEventListener('click', this.embla?.scrollNext, false);

    const handleKeydown = (e) => {
      if (e.key === 'ArrowLeft') {
        this.embla?.scrollPrev()
      } else if (e.key === 'ArrowRight') {
        this.embla?.scrollNext()
      }
    };

    this.keydownHandler = handleKeydown;

    document.addEventListener('keydown', handleKeydown);

    this.embla.on('select', () => {
      if (this.embla) {
        this.$store.modal.data = {
          selectedIndex: this.embla.selectedScrollSnap() + 1,
          activeUrl: urls[this.embla.selectedScrollSnap()],
        };
      }
    });

    this.embla.scrollTo(index, true);

    this.$watch('$store.modal.isOpen', (value) => {
      if (!value) {
        this.destroy();
      }
    });
  },
  destroy() {
    this.embla?.destroy();
    this.prevButtonNode?.removeEventListener('click', this.embla?.scrollPrev);
    this.nextButtonNode?.removeEventListener('click', this.embla?.scrollNext);
    document.removeEventListener('keydown', this.keydownHandler);
  },
})
