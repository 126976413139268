export default canvasEvent => fabric => {
  fabric.Canvas.prototype.historyInit = function() {
    // if historyInit already init
    if (this.historyNextState || this.historyNextState === null) return;
    // ! historyInit have to be executed
    // ! After the canvas is initilized
    // ! Before the historySaveAction
    this.historyUndo = [];
    this.historyProcessing = false;
    // ! init nextState value of null
    this.historyNextState = null;
  };

  fabric.Canvas.prototype.historyNext = function() {
    return this.toDatalessJSON();
  };

  fabric.Canvas.prototype.historySaveAction = function() {
    // If the historySaveAction run before historyInit
    if (typeof this.historyNextState === "undefined") this.historyInit();
    if (this.historyProcessing) {
      // Record the undo next state
      this.historyNextState = this.historyNext();
      return;
    }
    const json = this.historyNextState;
    if (!this.historyUndo) this.historyUndo = [];
    if (this.historyUndo.length >= 10) this.historyUndo.shift();
    if (json) this.historyUndo.push(json);
    // ! if the historyCollection is empty, init currentState as value of null (will be only executed on project init)
    if (!json && !this.historyUndo.length) this.historyUndo.push(json);

    this.historyNextState = this.historyNext();
  };

  fabric.Canvas.prototype.undo = function() {
    this.historyProcessing = true; // A flag to tell historySaveAction ignore the save action
    if (!this.historyUndo.length) {
      this.historyProcessing = false;
      return;
    }
    // ! currentState became previousState when undo
    const currentState = this.historyUndo.pop();
    // ! if the currentState is null, do not render
    if (currentState) {
      const klass = this.loadFromJSON(currentState).requestRenderAll();
      const checkKlass = () => {
        if (klass) {
          canvasEvent.emit("undo:render", klass);
          clearInterval(klassInterval);
        }
      };
      const klassInterval = setInterval(checkKlass, 300);
      // ! if the currentState has a state, the nextState will is the nextState(The real currentState) - current
      this.historyNextState = currentState;
    } else {
      // ! if the currentState is null, and historyCollection is empty, init currentState as value of null
      if (!this.historyUndo.length) this.historyUndo.push(currentState);
      // ! if the currentState is null, the nextState is the nextState(The real currentState) - retrived
      this.historyNextState = this.historyNext();
      canvasEvent.emit("undo:render", true);
    }
    canvasEvent.on("undo:render", v => {
      if (v) {
        this.historyProcessing = false;
        // ! the custom event to solve the Undo action does not trigger fabric event listeners
        canvasEvent.emit("undo", true);
      }
    });
  };

  return fabric;
};
