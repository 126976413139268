// import gsap from "gsap"
// todo: use gsap to animate content in

import tailwindConfig from "../../../tailwind.config.js"
import resolveConfig from "tailwindcss/resolveConfig"

export default (activeIndex, mainMenuGradients) => ({
  activeIndex,
  mainMenuGradients,
  getColourName() {
    return this.mainMenuGradients[this.activeIndex] || "arts"
  },
  getBackgroundGradient(direction = "r") {
    if (
      this.getColourName() === "boosted" ||
      (this.getColourName() === "kotahi" && direction !== "b")
    ) {
      direction = direction === "r" ? "l" : "r"
    }

    return `bg-gradient-${this.getColourName()}-${direction}`
  },
  getForegroundGradient() {
    return `bg-gradient-${this.getColourName()}-f`
  },
  getColour(type) {
    if (
      this.getColourName() === "boosted" ||
      this.getColourName() === "kotahi"
    ) {
      type = type === "primary" ? "secondary" : "primary"
    }

    return resolveConfig(tailwindConfig).theme.colors[this.getColourName()][
      type
    ]
  },
  setActiveIndex() {
    if (window.innerWidth > 1024 && this.activeIndex === -1) {
      this.activeIndex = 0
    }
  },
  navigateMenu(direction) {
    const menuItems = this.$refs.menuItems.querySelectorAll(
      ".js-site-menu-item-link",
    )
    const currentIndex = this.activeIndex
    const nextIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1

    if (nextIndex >= 0 && nextIndex < menuItems.length) {
      menuItems[nextIndex].focus()
      this.activeIndex = nextIndex
    }
  },
})
