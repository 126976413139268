import history from "./history";
import method from "./method";
import util from "./util";
import sprayBrush from "./sprayBrush";

export default {
  pipe: (...fns) => x => fns.reduce((v, f) => f(v), x),
  history,
  extendingMethods: method,
  extendingUtils: util,
  sprayBrush
};
