export default maxLength => ({
  input: null,
  remaining: maxLength,
  init() {
    if (maxLength) {
      this.input = this.$el.querySelector("textarea")

      this.input.addEventListener("input", () => {
        this.remaining = maxLength - this.input.value.length
      })
    }
  },
})
