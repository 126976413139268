import { fabric as fabricBase } from "fabric"
import fabricEnhancer from "../fabric"
// import axios from "axios";

import { canvasEvent } from "../events"

const { pipe, extendingMethods, extendingUtils, history, sprayBrush } =
  fabricEnhancer

let fabric = pipe(
  extendingUtils,
  extendingMethods,
  history(canvasEvent),
  sprayBrush,
)(fabricBase)

export default (
  queriedArtImage,
  queriedArtImageTitle,
  csrfName,
  csrfToken,
  siteUrl,
  fullPath,
) => ({
  canvasId: "artIs",
  defaultSize: { width: 1680, height: 882 },
  entryId: 0,
  canvas: null,
  artist: "Anonymous",
  artistCoded: "anonymous",
  originalSize: {
    width: 0,
    height: 0,
  },
  settings: {
    preserveObjectStacking: true,
    isDrawingMode: false,
    width: 100,
    height: 100,
  },
  settingsExport: {
    format: "jpeg",
    quality: 0.975,
    multiplier: 1,
  },
  // Default values reflect when the canvas' width in 1680px
  sprayWidth: 110,
  defaultSprayWidth: 110,
  defaultBrushWidth: 10,
  defaultFontSize: 116,
  scaleX: 1,
  stateUndo: false,
  text: "Art",
  textLayer: 0,
  cText: null,
  fontFamily:
    "NB_Akademie, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
  color: "#000000",
  activeObject: null,
  selectedTool: String,
  selectedPencil: 1,
  palette: ["#000000", "#FFFFFF", "#7DFB84", "#E0E0DD"],
  swatch: 0,
  bgImageList: [
    "/assets/img/canvas-1.png",
    "/assets/img/canvas-2.png",
    "/assets/img/canvas-3.png",
    "/assets/img/canvas-4.png",
    "/assets/img/canvas-5.png",
    "/assets/img/canvas-6.png",
    "/assets/img/canvas-7.png",
    "/assets/img/canvas-8.png",
  ],
  currentBg: 0,
  isSubmittingDisabled: true,
  isSubmitting: false,
  submittingSuccess: false,
  submittingError: false,
  imageUrlBlob: "",
  showHelpModal: false,
  showShareModal: false,
  preventKeyListener: false,

  queriedArtImage: JSON.parse(queriedArtImage),
  showQueriedArtImage: queriedArtImage ? true : false,

  get ratio() {
    const { width, height } = this.defaultSize
    return Math.round((width / height) * 100) / 100
  },
  get paddingBottom() {
    const { width, height } = this.defaultSize
    return `padding-bottom: ${(height / width) * 100}%`
  },
  initFabric() {
    this.canvas = Alpine.raw(new fabric.Canvas(this.canvasId, this.settings))
    this.canvas.on({
      "selection:created": this.setObjectActive.bind(this),
      "selection:updated": this.setObjectActive.bind(this),
      "selection:cleared": this.setObjectActive.bind(this),
      // History related actions
      "object:added": () => this.onCanvasChange(false),
      "object:removed": () => this.onCanvasChange(false),
      "object:modified": () => this.onCanvasChange(false),
    })
  },
  addText(text) {
    const { scaleX, defaultFontSize, defaultSize } = this

    const sizeRatio =
      Math.round((defaultFontSize / defaultSize.width) * 1000) / 1000
    const size = Math.round(scaleX * defaultSize.width * sizeRatio)
    this.selectedTool = "Select"
    if (!this.text && !text) return
    if (!this.text) this.text = text
    if (this.cText) {
      this.cText.set({ text: this.text })
      this.canvas.requestRenderAll()
    } else if (this.activeObject && this.activeObject.type === "text") {
      this.activeObject.set({ text: this.text })
      this.canvas.requestRenderAll()
    } else {
      this.textLayer += 1
      const fontSize = 26
      const position = {
        top: Math.round(200 * scaleX),
        left: Math.round(200 * scaleX),
      }

      const { width } = this.canvas
      if (width) {
        position.left = width / 2 - Math.round(100 * scaleX)
        position.top =
          Math.round(size * 0.2) + this.textLayer * Math.round(size / 1.2)
      }
      this.cText = new fabric.IText(this.text, {
        fontSize: size,
        left: position.left,
        top: position.top,
        fill: this.color,
        fontWeight: 900,
        fontFamily: this.fontFamily,
      })
      this.canvas.add(this.cText)
    }
    this.canvas.isDrawingMode = false
    this.cText = null
  },
  addImage(e) {
    const canvas = Alpine.raw(this.canvas)
    let { ratio, isDrawingMode } = this
    const { width: cW, height: cH } = canvas
    const reader = new FileReader()
    reader.onload = event => {
      const imgObj = new Image()
      imgObj.src = event.target.result as string
      imgObj.onload = function () {
        const image = new fabric.Image(imgObj)
        const { width: iW, height: iH } = image

        image.hasRotatingPoint = false

        const imageRatio = Math.round(iW / iH)

        // d = display
        const dW = Math.round(cW / 2)
        const dH = Math.round(cH / 2)

        if (imageRatio > ratio) {
          // Landscape // square
          if (iW > dW) image.scaleToWidth(dW)
        } else {
          // Portrait
          if (iH > dH) image.scaleToHeight(dH)
        }

        canvas.centerObject(image)
        canvas.add(image)
        canvas.isDrawingMode = false
        // canvas.setActiveObject(image)
        canvas.requestRenderAll()
        e.target.value = ""
      }
    }
    reader.readAsDataURL(e.target.files[0])
    this.selectedTool = "Select"
  },
  initHistory() {
    this.canvas.historyInit()
    setTimeout(() => {
      canvasEvent.on("undo", () => this.onCanvasChange(true))
    })
  },
  initBrush(brush) {
    const { currentTool } = this
    this.canvas.isDrawingMode = true
    this.canvas.freeDrawingBrush = new fabric[brush](this.canvas)
  },
  updateBrush(settings) {
    Object.keys(settings).forEach(key => {
      this.canvas.freeDrawingBrush[key] = settings[key]
    })
  },
  usePencils() {
    this.usePencil(this.selectedPencil)
  },
  usePencil(size) {
    this.selectedTool = "Pencils"
    this.selectedPencil = size
    this.initBrush("PencilBrush")
    this.updateBrush({
      width: size,
      color: this.hex2Rgba(0.8)(this.color),
    })
  },
  updateSprayWidth() {
    const { defaultSprayWidth, defaultSize, scaleX } = this
    const widthRatio =
      Math.round((defaultSprayWidth / defaultSize.width) * 1000) / 1000
    this.sprayWidth = Math.round(scaleX * defaultSize.width * widthRatio)
  },
  useSpray() {
    this.initBrush("SprayBrush")
    this.selectedTool = "Spray"
    this.canvas.freeDrawingBrush.width = this.sprayWidth
    this.canvas.freeDrawingBrush.changeColor(this.color)
    this.canvas.freeDrawingBrush.opacity = 1
  },
  useBrush() {
    const { defaultBrushWidth, defaultSize, scaleX } = this
    const widthRatio =
      Math.round((defaultBrushWidth / defaultSize.width) * 1000) / 1000
    const brushWidth = Math.round(scaleX * defaultSize.width * widthRatio)
    this.selectedTool = "Brush"
    this.initBrush("PencilBrush")
    this.updateBrush({
      width: brushWidth > 5 ? brushWidth : 5,
      strokeLineCap: "butt",
      color: this.hex2Rgba(0.8)(this.color),
    })
  },
  onCanvasChange(isUndo) {
    // Empty Canvas checking
    const objects = this.canvas.getObjects()
    if (objects.length) this.isSubmittingDisabled = false
    // Undo
    if (!isUndo) this.canvas.historySaveAction()
    if (this.canvas.historyUndo.length <= 1) {
      this.stateUndo = false
    } else {
      this.stateUndo = true
    }
  },
  setOriginalSize() {
    this.originalSize.width = this.settings.width
    this.originalSize.height = this.settings.height
  },
  setSelect() {
    this.selectedTool = "Select"
    this.canvas.isDrawingMode = false
  },
  setBackgroundImage() {
    const { canvas, bgImageList } = this
    const bgImagesLength = bgImageList.length
    if (this.currentBg >= bgImagesLength) {
      this.currentBg = 0
    }
    if (!bgImageList[this.currentBg]) {
      canvas.backgroundImage = null
      canvas.requestRenderAll()
    } else {
      fabric.Image.fromURL(
        bgImageList[this.currentBg],
        img => {
          if (img.width === 0) return
          img.scaleToWidth(canvas.width)
          img.scaleToHeight(canvas.height)
          canvas.setBackgroundImage(img)
          this.onCanvasChange(false)
          canvas.requestRenderAll()
        },
        {
          crossOrigin: "anonymous",
        },
      )
    }
    this.currentBg += 1
  },
  setColor() {
    const paletteLength = this.palette.length
    this.swatch += 1
    if (this.swatch >= paletteLength) {
      this.swatch = 0
    }
    this.color = this.palette[this.swatch]
  },
  setObjectActive() {
    this.activeObject = this.canvas.getActiveObject()
    if (!this.activeObject) return
    this.activeObject.set({
      borderColor: "#191919",
      cornerColor: "#191919",
      lockUniScaling: true,
    })
  },
  setKeyDown(event) {
    if (this.preventKeyListener) return
    // https://keycode.info/
    switch (event.keyCode) {
      case 8: // delete
        return this.toDelete()
      case 16:
        return
      default:
        return
    }
  },
  setKeyUp() {
    if (this.preventKeyListener) return
    // @ts-ignore
    switch (event.keyCode) {
      case 16: // shiftdown
        return
      default:
        return
    }
  },
  setCanvasSize() {
    if (!this.$refs) return
    if (!this.$refs.container) return
    if (!this.$refs.container.clientWidth) return
    if (!this.$refs.container.clientHeight) return
    this.settings.height = this.$refs.container.clientHeight
    this.settings.width = this.$refs.container.clientWidth
    this.scaleX =
      Math.round((this.settings.width / this.defaultSize.width) * 1000) / 1000
  },
  setOverlayImage() {
    const { canvas, settings } = this
    const width = canvas.width / 5
    const left = canvas.width * 0.04117

    fabric.loadSVGFromString(
      `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 207.78 171.03">
        <path d="M127.07,110H108.15v18.59h18.92Zm0,37.41H108.15V166h18.92Zm-89.81,4.38a31,31,0,0,1-1.46-7.46L54.61,141a17.41,17.41,0,0,0,.49,4.06A15.33,15.33,0,0,0,56.7,149a14.82,14.82,0,0,0,2.67,3.44,11.86,11.86,0,0,0,4,2.4,14.91,14.91,0,0,0,5.33.92q6.21,0,9.65-2.51a8,8,0,0,0,3.43-6.78,7.53,7.53,0,0,0-1.11-4.16,7.08,7.08,0,0,0-3.16-2.65,17,17,0,0,0-4.11-1.26c-1.37-.24-2.94-.49-4.71-.76l-8.48-1.48a38.64,38.64,0,0,1-8.21-2.4,27.88,27.88,0,0,1-7.17-4.16A19.24,19.24,0,0,1,38,114.7a21.72,21.72,0,0,1,1.78-8.89,19.85,19.85,0,0,1,4.76-6.73,28.34,28.34,0,0,1,6.95-4.59,34.59,34.59,0,0,1,8.21-2.73,44.38,44.38,0,0,1,8.63-.84,38,38,0,0,1,15.13,3,29.2,29.2,0,0,1,11.61,8.76,20,20,0,0,1,4.5,12.73l-17.41,2.92a13.29,13.29,0,0,0-1.64-5.84A10.73,10.73,0,0,0,77,108.73a16.42,16.42,0,0,0-4.27-1.94,15.55,15.55,0,0,0-4.37-.63,19.68,19.68,0,0,0-5.79.79,9.06,9.06,0,0,0-4.3,2.87,7.84,7.84,0,0,0-1.75,5.24q0,2.81,3.37,4.92t10,3.12l5.9,1q2.59.44,5.89,1.33a36.87,36.87,0,0,1,5.76,2,32,32,0,0,1,5,2.91,17.77,17.77,0,0,1,4.14,4,19.42,19.42,0,0,1,2.62,5.3,21.46,21.46,0,0,1,1,6.83,21.77,21.77,0,0,1-8.43,17.68Q83.38,171,68.67,171a43.92,43.92,0,0,1-12.35-1.62,29.35,29.35,0,0,1-9.17-4.27,27.93,27.93,0,0,1-9.89-13.4M25,92H6.32V170H25Zm125.5-76.92V0h57.25V15.08H188.53V78H170.05V15.08Zm-50.13.22h15.46a7.52,7.52,0,0,1,5.54,2.76A9.49,9.49,0,0,1,124,24.81a9.52,9.52,0,0,1-2.57,6.76,7.52,7.52,0,0,1-5.54,2.76H100.4Zm0,62.65V49.63h3.52L127,78h21.13L124.29,48.6h.11A23.61,23.61,0,0,0,137.52,40a24.32,24.32,0,0,0,4.94-15.14,23.86,23.86,0,0,0-7.18-17.73Q128.11,0,116.83,0H81.7V78ZM37,24.38l7.94,24.81h-16ZM19.57,78l4.32-13.51h26L54.44,78h19.4L45.46,0H28.33L0,78Z"/>
      </svg>
      `,
      (svgElements, options) => {
        const overlaySvg = new fabric.util.groupSVGElements(
          svgElements,
          options,
        )
        overlaySvg.set({
          left,
          top: left,
        })
        overlaySvg.scaleToWidth(settings.width / 7.5)
        canvas.setOverlayImage(overlaySvg, () => canvas.requestRenderAll())
      },
      null,
      { crossOrigin: "anonymous" },
    )
  },
  toUndo() {
    const preSelectedTool = this.selectedTool
    this.selectedTool = "Select"
    this.canvas.undo()
    this.selectedTool = preSelectedTool
  },
  toDelete() {
    if (!this.activeObject) return
    if (
      this.activeObject.type === "activeSelection" &&
      this.activeObject._objects &&
      Array.isArray(this.activeObject._objects)
    ) {
      this.activeObject._objects.forEach(obj => {
        this.canvas.remove(obj)
      })
    } else {
      this.canvas.remove(this.activeObject)
    }
    this.canvas.discardActiveObject()
  },
  toPost() {
    //   const outputScaleX =
    //     Math.round((this.outputWidth / this.settings.width) * 1000) / 1000;
    //   this.resetSubmittingState();
    //   this.isSubmitting = true;
    //   this.settingsExport.multiplier = outputScaleX;

    //   let trimmedArtist = "";
    //   const artist = window.prompt("Please enter your name:");
    //   // artist === null is cancel, artist === '' is ok
    //   if (typeof artist !== "string") {
    //     this.isSubmitting = false;
    //     return;
    //   }
    //   if (artist) {
    //     trimmedArtist = artist
    //       .trim()
    //       .replace(/[^a-zA-Z0-9]+/g, " ") // remove special characters
    //       .replace(/[ \t]+$/g, "") // remove trailing whitespace
    //       .replace(/\s+/g, "_"); // replace whitespace to '_'
    //   }
    //   if (trimmedArtist) {
    //     this.artist = artist; // For display name, e.g. my name 200
    //     this.artistCoded = trimmedArtist; // For coded name, e.g. my_name_200
    //   }
    //   const imageData = this.canvas.toDataURL(this.settingsExport);
    //   const { format: imageFormat } = this.settingsExport;
    //   axios
    //     .get(imageData, { responseType: "arraybuffer" })
    //     .then(response => {
    //       const blob = new Blob([response.data], {
    //         type: `image/${imageFormat}`
    //       });
    //       this.imageUrlBlob = URL.createObjectURL(blob);
    //       const _form = this.$refs.postCanvas;
    //       const form = new FormData(_form);
    //       form.append(this.csrfName, this.csrfToken);
    //       form.append("action", "guest-entries/save");
    //       form.append("sectionUid", this.sectionUid);
    //       form.append("title", this.artist);
    //       form.append(
    //         "fields[imageArt]",
    //         blob,
    //         `art-${this.artistCoded}.${imageFormat}`
    //       );
    //       return axios.post("/events/arts-month", form);
    //     })
    //     .then(({ data }) => {
    //       if (data.id) this.entryId = data.id;
    //       this.isSubmitting = false;
    //       this.submittingSuccess = true;
    this.resetCanvas()
    //       this.showShareModal = true;
    //       if (fbq) fbq("track", "CompleteRegistration");
    //     })
    //     .catch(err => {
    //       if (err && err.data && err.data.error) console.error(err.data.error);
    //       this.isSubmitting = false;
    //       this.submittingError = true;
    //     });

    this.$store.modal.open(
      this.$refs.artCanvasShareModal.content.cloneNode(true),
    )
  },
  sendForward() {
    if (!this.activeObject) return
    this.canvas.bringForward(this.activeObject)
  },
  sendBackward() {
    if (!this.activeObject) return
    this.canvas.sendBackwards(this.activeObject)
  },
  resetSubmittingState() {
    this.submittingError = false
    this.submittingSuccess = false
    this.isSubmitting = false
    this.showShareModal = false
    this.artist = "Anonymous"
    this.artistCoded = "anonymous"
  },
  resetCanvas() {
    const { canvas } = this
    // 01. remove all object on canvas, excludes: gb-image, bg-color, overlay-image, overlay-color
    canvas.remove(...canvas.getObjects())
    canvas.discardActiveObject()
    // 02. Reset background image
    this.currentBg = 0
    this.setBackgroundImage()
    // 03. Reset color
    // any number that bigger than the palette length
    this.swatch = this.palette.length + 1
    this.setColor()
    // 04, init Defaul pen tools
    this.usePencils()
  },
  hex2Rgba(opacity = 1) {
    const { color: defaultColor } = this
    return hex => {
      function toRgba(h) {
        let c
        c = h.substring(1).split("")
        if (c.length == 3) c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        c = "0x" + c.join("")
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
          ",",
        )},${opacity})`
      }
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        return toRgba(hex)
      }
      return toRgba(defaultColor)
    }
  },
  debounce(cb) {
    let timer
    return event => {
      if (timer) clearTimeout(timer)
      timer = setTimeout(cb, 100, event)
    }
  },

  init() {
    this.resetSubmittingState()
    this.$nextTick(() => {
      // The order matters
      this.setCanvasSize()
      this.initFabric()
      this.setOverlayImage()
      this.initHistory()
      this.setOriginalSize()
      this.setBackgroundImage()
      window.addEventListener("keydown", this.setKeyDown.bind(this))
      window.addEventListener("keyup", this.setKeyUp.bind(this))
      window.addEventListener("resize", this.setCanvasSize.bind(this))
      this.usePencils()
      // First Render
      this.canvas.requestRenderAll()
      this.updateSprayWidth()

      this.$watch("color", (newVal, oldVal) => {
        if (newVal === oldVal) return true
        this.updateBrush({ color: newVal })
      })
      this.$watch("activeObject", (newObject, oldObject) => {
        if (!newObject || !newObject.hasControls) return
        if (newObject.type === "image" && !newObject.customType) return
        if (newObject.type === "i-text") {
          newObject.on("editing:entered", () => {
            this.preventKeyListener = true
          })
          newObject.on("editing:exited", () => {
            this.preventKeyListener = false
          })
          return
        }
        newObject.hasControls = false
      })
      this.$watch("text", (newText, oldText) => {
        if (newText === oldText) return
      })
      this.$watch("settings", newSettings => {
        const { originalSize } = this
        const { width, height } = newSettings
        // const scaleRatio = Math.min(width, height);
        // Update Zoom
        const scaleX = Math.round((width / originalSize.width) * 1000) / 1000
        // const yZoom = Math.round((height / originalSize.heigth) * 1000) / 1000;
        this.canvas.setZoom(scaleX)
        // Get new Zoom
        const zoom = this.canvas.getZoom()
        // Apply new Zoom
        this.canvas.setWidth(originalSize.width * zoom)
        this.canvas.setHeight(originalSize.height * zoom)
        this.canvas.setBackgroundColor("#E0E0DD")
        this.canvas.requestRenderAll()
      })
      this.$watch("imageUrlBlob", url => {
        canvasEvent.emit("update:imageUrlBlob", url)
      })
      this.$watch("artist", name => {
        canvasEvent.emit("update:artist", name)
      })
    })
  },

  destroy() {
    window.removeEventListener("keydown", this.setKeyDown.bind(this))
    window.removeEventListener("keyup", this.setKeyUp.bind(this))
    window.removeEventListener("resize", this.setCanvasSize.bind(this))
  },
})
