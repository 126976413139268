export default (url: string) => ({
  thumbnailUrl: null,
  videoUrl: url,
  getYouTubePoster(videoUrl) {
    const videoId = new URL(videoUrl).searchParams.get("v")
    if (videoId) {
      return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
    }
    return null
  },
  async getVimeoPoster(videoUrl) {
    const videoId = videoUrl.split("/").pop()
    const response = await fetch(
      `https://vimeo.com/api/v2/video/${videoId}.json`,
    )

    if (response.ok) {
      const data = await response.json()
      return data[0].thumbnail_large
    }
  },
  async fetchThumbnail() {
    if (this.videoUrl.includes("youtube.com")) {
      this.thumbnailUrl = this.getYouTubePoster(this.videoUrl)
    } else if (this.videoUrl.includes("vimeo.com")) {
      this.thumbnailUrl = await this.getVimeoPoster(this.videoUrl)
    }
  },
  init() {
    this.fetchThumbnail()
  },
})
