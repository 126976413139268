export default ({ projectDetails, projectDetailsChanged }) => ({
  form: "saveForm",
  projectDetailsChanged,
  projectDetails,
  isEditingForm: [
    { name: "title", value: false },
    { name: "websiteUrl", value: false },
    { name: "description", value: false },
    { name: "targetAmount", value: false },
    { name: "disciplines", value: false },
    { name: "collaborators", value: false },
    { name: "coverMedia", value: false },
  ],
  currentStep: null as "options" | "cropper" | "videoPanel",
  handleRemove(type) {
    if (type === "image") {
      this.projectDetails.coverImage = ""
      this.$refs.fileField.type = "text"
    }
    if (type === "video") {
      this.projectDetails.coverVideo = ""
    }
  },
  makeUrlValid(url: string): string {
    if (!url) {
      return url
    }

    const hasProtocol = /^(http|https):\/\//i.test(url)
    if (!hasProtocol) {
      return `https://${url}`
    }

    return url
  },
  updateCoverVideo(eventDetail) {
    this.projectDetails.coverVideo = this.makeUrlValid(eventDetail.src)
    this.currentStep = null
  },
  updateCoverImage(eventDetail) {
    this.projectDetails.coverImage = eventDetail
    this.currentStep = null
  },
  updateProjectDetail(eventDetail) {
    this.projectDetails[eventDetail.name] = eventDetail.value
  },
  closeEditor() {
    this.currentStep = null
  },
  toggleSubmitButton(value) {
    this.$refs.submitButton.disabled = value
  },
  updateIsEditingForm({ name, value }) {
    const field = this.isEditingForm.find(item => item.name === name)
    if (field) {
      field.value = value
    }

    const isEditing = this.isEditingForm.some(item => item.value)
    this.toggleSubmitButton(!this.projectDetailsChanged || isEditing)
  },
  handlePreviewSubmit(e: SubmitEvent) {
    e.preventDefault()
    this.form = "previewForm"

    this.$nextTick(() => {
      this.$el.dispatchEvent(new Event("previewSubmit"))
    })
  },
  handleSaveSubmit(e: SubmitEvent) {
    e.preventDefault()
    this.form = "saveForm"

    this.$nextTick(() => {
      this.$el.dispatchEvent(new Event("saveSubmit"))
    })
  },
  init() {
    this.toggleSubmitButton(!this.projectDetailsChanged)

    this.$watch("projectDetails", () => {
      this.projectDetailsChanged = true
      this.toggleSubmitButton(false)
    })

    this.$watch("currentStep", value => {
      this.updateIsEditingForm({ name: "coverMedia", value: value !== null })
    })
  },
})
