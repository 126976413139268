import flatpickr from "flatpickr"

export const formField = (id = null, options = null) => ({
  unformattedValue: null,
  options: options ? JSON.parse(options) : [],
  titleMap: {},
  selectedTitle: "",

  handleInput(e) {
    this.hasChanged = this.inputValue !== e.target.value
    this.inputValue = e.target.value

    if (this.inputType === "target") {
      this.unformattedValue = e.target.value
    }

    if (this.inputType === "radio") {
      this.updateSelectedTitle()
    }
  },

  handleTextareaInput(eventDetail) {
    if (eventDetail.id === id) {
      this.hasChanged = this.inputValue !== eventDetail.value
      this.inputValue = eventDetail.value
    }
  },

  focusInput() {
    const inputEl = this.$refs.input
    if (inputEl && inputEl.offsetParent !== null) {
      inputEl.focus()

      if (this.inputType !== "target" && this.inputType !== "radio") {
        inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length)
      }
    }
  },

  formatTargetAmount(value) {
    return new Intl.NumberFormat().format(value.replace(/\D/g, ""))
  },

  createTitleMap() {
    this.options.forEach(option => {
      this.titleMap[option.id] = option.title
    })
  },

  updateSelectedTitle() {
    this.selectedTitle = this.titleMap[this.inputValue] || ""
  },

  initDateWithTimezone() {
    const input = this.$refs.date
    const hiddenEl = document.getElementById(
      `${input.id}-hidden`,
    ) as HTMLInputElement
    const date = new Date(input.value).toISOString()

    hiddenEl.value = date
  },

  handleDatePicker(value) {
    const input = this.$refs.date

    if (value) {
      const startDate = document.querySelector(
        "#startDate",
      ) as HTMLInputElement | null

      const minStartDate = new Date()
      const minClosingDate = startDate ? startDate.value : new Date()

      const minDate = input.id === "startDate" ? minStartDate : minClosingDate

      // Initialize flatpickr if isEditing is true
      this.fp = flatpickr(input, {
        enableTime: true,
        minDate: minDate,
        onClose: function (selectedDates) {
          if (selectedDates.length > 0) {
            const hiddenElId = `${input.id}-hidden`
            const hiddenEl = document.getElementById(
              hiddenElId,
            ) as HTMLInputElement
            const dateWithTimezone = selectedDates[0].toISOString()

            // Update the hidden element with the timezone date
            hiddenEl.value = dateWithTimezone
          }
        },
      })
    } else if (this.fp) {
      // Destroy flatpickr if isEditing is false
      this.fp.destroy()
      this.fp = null // Clear reference to flatpickr instance
    }
  },

  init() {
    if (this.inputType === "target") {
      this.unformattedValue = this.inputValue.replace(/\D/g, "")
    }

    if (this.inputType === "radio") {
      this.createTitleMap()
      this.updateSelectedTitle()
    }

    if (this.inputType === "date") {
      // Initialize date with timezone
      this.initDateWithTimezone()
    }

    this.$watch("isEditing", value => {
      if (value) {
        this.$nextTick(() => this.focusInput())
      }

      if (this.inputType === "date") {
        this.handleDatePicker(value)
      }
    })
  },
})

export const formGroupManager = groups => ({
  formGroups: groups ? JSON.parse(groups) : [],
  formGroupsJson: null,

  addFormGroup() {
    const id = `new:${this.formGroups.length}`

    this.formGroups.push({
      id,
      email: "",
      isCoOwner: false,
    })
  },

  removeFormGroup(index) {
    this.formGroups.splice(index, 1)
  },

  handleInputChange(index, field, value) {
    console.log(value)
    this.formGroups[index][field] = value
  },

  init() {
    this.$watch("formGroups", value => {
      this.hasChanged = true
      this.formGroupsJson = JSON.stringify(this.formGroups)
    })
  },
})
