export default () => ({
  videoLinkIsValid: true,
  videoInput: "",
  hasSubmitted: false,

  handleVideoLinkValidation(url: string) {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/
    const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com)\/.+$/
    this.videoLinkIsValid = youtubeRegex.test(url) || vimeoRegex.test(url)
  },

  handleVideoSave() {
    this.hasSubmitted = true
    this.handleVideoLinkValidation(this.videoInput)

    if (this.videoLinkIsValid) {
      this.$dispatch("update-cover-video", {
        src: this.videoInput,
      })

      if (this.videoThumbnail) {
        this.$dispatch("update-image", this.videoThumbnail)
        this.videoThumbnail = null
      }
    }
  },
})
