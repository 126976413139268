import { CountUp } from "countup.js"

export const animateCountUp = (item, index, timeout = 0, isMobile = false) => {
  const countItem = item.querySelector(".js-count")
  const { value, valueMobile, suffix, prefix, suffixMobile } = countItem.dataset

  const countValue = isMobile && valueMobile ? valueMobile : value
  const suffixValue = isMobile && suffixMobile ? suffixMobile : suffix

  const countUp = new CountUp(countItem, countValue, {
    duration: 2,
    separator: ",",
    suffix: suffixValue,
    prefix: prefix,
  })

  setTimeout(() => {
    countUp.start()
  }, index * timeout)
}
