import { inherit, current, transparent, black, white } from "tailwindcss/colors"
import defaultTheme from "tailwindcss/defaultTheme"

export default {
  content: [
    "./templates/**/*.{twig,html}",
    "./src/js/**/*.{js,ts}",
    "./modules/core/web/twig/functions/GradientClass.php",
    "./modules/core/web/twig/functions/ColourClass.php",
  ],
  safelist: [
    "!space-y-0",
    "!gap-y-0",
    "lg:-mt-6",
    {
      pattern: /bg-gradient-./,
      variants: ["lg", "after"],
    },
  ],
  theme: {
    fontFamily: {
      sans: ["Soehne", ...defaultTheme.fontFamily.sans],
    },
    fontSize: {
      xs: ["12px", "1"],
      sm: ["14px", "1.4"],
      base: ["16px", "1.4"],
      lg: ["18px", "1.4"],
      xl: ["20px", "1.3"],
      "2xl": ["24px", "1.3"],
      "3xl": ["30px", "1.2"],
      "4xl": ["36px", "1.3"],
      "5xl": ["48px", "1.2"],
      "6xl": ["56px", "1"],
      "7xl": ["72px", "1"],
      "8xl": ["96px", "1"],
      "9xl": ["120px", "1"],
    },
    colors: {
      charcoal: "#312B2B",
      boosted: {
        primary: "var(--boosted-primary)",
        secondary: "var(--boosted-secondary)",
      },
      // All in for arts
      "all-in": {
        primary: "var(--all-in-primary)",
        secondary: "var(--all-in-secondary)",
      },
      kotahi: {
        primary: "var(--kotahi-primary)",
        secondary: "var(--kotahi-secondary)",
      },
      // Moment in time
      moment: {
        primary: "var(--moment-primary)",
        secondary: "var(--moment-secondary)",
      },
      // Katherine Mansfield
      mansfield: {
        primary: "var(--mansfield-primary)",
        secondary: "var(--mansfield-secondary)",
      },
      // Harriet Friedlander
      friedlander: {
        primary: "var(--friedlander-primary)",
        secondary: "var(--friedlander-secondary)",
      },
      icons: {
        primary: "var(--icons-primary)",
        secondary: "var(--icons-secondary)",
      },
      springboard: {
        primary: "var(--springboard-primary)",
        secondary: "var(--springboard-secondary)",
      },
      laureate: {
        primary: "var(--laureate-primary)",
        secondary: "var(--laureate-secondary)",
      },
      arts: {
        primary: "var(--arts-primary)",
        secondary: "var(--arts-secondary)",
        tertiary: "var(--arts-tertiary)", // 50%
      },
      childrens: {
        primary: "var(--childrens-primary)",
        secondary: "var(--childrens-secondary)",
      },
      "new-gen": {
        primary: "var(--new-gen-primary)",
        secondary: "var(--new-gen-secondary)",
      },
      "boost-otautahi": {
        primary: "var(--boost-otautahi-primary)",
        secondary: "var(--boost-otautahi-secondary)",
      },
      "boosted-search": {
        primary: "var(--boosted-search-primary)",
        secondary: "var(--boosted-search-secondary)",
      },
      white,
      black,
      transparent,
      inherit,
      current,
      "success-green": "#01A054",

      "header-primary": "var(--header-primary)",
      "menu-primary": "var(--menu-primary)",
      "menu-secondary": "var(--menu-secondary)",
    },
    extend: {
      backgroundImage: {
        "header-primary-gradient": "var(--header-primary-gradient)",
        "header-secondary-gradient": "var(--header-secondary-gradient)",
        "page-gradient": "var(--page-gradient)",
        "page-gradient-solid": "var(--page-gradient-solid)",
        "boosted-hero":
          "linear-gradient(180deg, var(--boosted-secondary) 0%, var(--boosted-primary) 61%, var(--arts-primary) 100%)",
        "boosted-hero-blocks":
          "linear-gradient(49deg, rgba(227, 222, 218, 0.40) 0%, rgba(247, 247, 244, 0.02) 100%)",
      },
      screens: {
        xs: "475px",
        ...defaultTheme.screens,
      },
      spacing: {
        gs: "var(--gutter-size)",
        ogs: "var(--outside-gutter-size)",
        18: "4.5rem",
      },
      flexBasis: {
        dynamic: "var(--basis-dynamic)",
      },
    },
  },
  plugins: [],
}
