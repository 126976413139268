export default () => ({
  imageToCrop: null,
  isEditing: false,
  hasChanged: false,
  videoThumbnail: null,

  handleButtonClick() {
    this.currentStep = "options"
  },

  cropPhoto() {
    this.imageToCrop = this.projectDetails.coverImage.dataUrl
    this.currentStep = "cropper"
  },

  addVideoLink() {
    this.currentStep = "videoPanel"
  },

  handleFileChange(event) {
    const file = event.target.files[0]

    this.projectDetails.coverImage.name = file.name
    this.projectDetails.coverImage.type = file.type

    this.$refs.fileField.files = event.target.files

    if (file) {
      const reader = new FileReader()
      reader.onload = e => {
        this.currentStep = "cropper"
        this.imageToCrop = e.target.result
      }
      reader.readAsDataURL(file)
    }
  },

  async handleUpdateImage(eventDetail) {
    const response = await fetch(eventDetail.dataUrl)
    const blob = await response.blob()

    const { name, type } = this.projectDetails.coverImage
    const croppedFile = new File([blob], name, { type })

    const dataTransfer = new DataTransfer()
    dataTransfer.items.add(croppedFile)
    this.$refs.fileField.files = dataTransfer.files
  },
})
