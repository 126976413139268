import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export default () => ({
  init() {
    const children = this.$el.children
    gsap.set(children, { opacity: 0 })
    ScrollTrigger.batch(children, {
      start: "top 55%",
      end: "bottom 33.33%",
      onEnter: batch => {
        gsap.to(batch, {
          opacity: 1,
          stagger: 0.1,
          duration: 1.5,
          lazy: true,
        })
      },
    })
    gsap.utils.toArray(children).forEach((child: any, index) => {
      const startY = 125 + index * 1
      const endY = -125 - index * 1
      gsap.fromTo(
        child,
        { y: startY },
        {
          y: endY,
          scrollTrigger: {
            trigger: child,
            scrub: 1,
            invalidateOnRefresh: true,
          },
        },
      )
    })

    this.$store.lenis.on("scroll", ScrollTrigger.update)

    gsap.ticker.add(time => {
      this.$store.lenis.raf(time * 1000)
    })

    gsap.ticker.lagSmoothing(0)
  },
})
