import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import Lenis from "lenis"
import "lenis/dist/lenis.css"

export default () => ({
  animateBackground() {
    this.$store.lenis = new Lenis({
      duration: 0.5,
      wheelMultiplier: 0.95,
      easing: t => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      prevent: node => node.id === "styleguide",
    })
    const section = this.$el
    const stops = section.querySelectorAll("[data-gradient-stop]")

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top top",
        end: "bottom bottom",
        scrub: 2,
        invalidateOnRefresh: true,
      },
    })

    stops.forEach(stop => {
      const colour = stop.getAttribute("data-gradient-stop")

      tl.to(section, {
        background: colour,
        lazy: true,
      })
    })

    this.$store.lenis.on("scroll", ScrollTrigger.update)

    gsap.ticker.add(time => {
      this.$store.lenis.raf(time * 1000)
    })

    gsap.ticker.lagSmoothing(0)
  },

  init() {
    this.animateBackground()
  },
})
