import gsap from "gsap"

export default () => ({
  themeColours: ["transparent", "transparent"],
  gradientStops: ["25%", "100%"],
  activeIndex: 1,
  get backgroundGradient() {
    return `linear-gradient(to bottom, ${this.themeColours[0]} ${this.gradientStops[0]}, ${this.themeColours[1]} ${this.gradientStops[1]})`
  },
  getColourVariables(gradient) {
    const docEl = getComputedStyle(document.documentElement)
    const primary = docEl.getPropertyValue(`--${gradient}-primary`)
    const secondary = docEl.getPropertyValue(`--${gradient}-secondary`)

    return [primary, secondary]
  },
  getGradientStops(gradient) {
    if (gradient == "laureate") {
      return (this.gradientStops = ["40%", "100%"])
    }

    return (this.gradientStops = ["25%", "100%"])
  },
  setInitialGradient(gradient) {
    this.themeColours = this.getColourVariables(gradient)
  },
  handleMouseEnter(gradient, index) {
    this.themeColours = this.getColourVariables(gradient)
    this.gradientStops = this.getGradientStops(gradient)
    this.activeIndex = index
  },
  init() {
    const firstItem = this.$el.querySelector("a[data-gradient]")

    if (!firstItem) {
      return
    }

    const firstItemGradient = firstItem.dataset.gradient
    this.getGradientStops(firstItemGradient)
    this.setInitialGradient(firstItemGradient)
    this.$refs.gradient.style.background = this.backgroundGradient

    this.$watch("themeColours", value => {
      gsap.to(this.$refs.gradient, {
        background: this.backgroundGradient,
        duration: 0.5,
      })
    })
  },
})
