import EmblaCarousel from "embla-carousel"

export default (isMobile, desktopDisplayAsCarousel = false) => ({
  emblaNode: null,
  initEmbla() {
    const {carousel, next, prev} = this.$refs
    const rootNode = desktopDisplayAsCarousel ? carousel : this.$el

    this.emblaNode = EmblaCarousel(rootNode, {
      align: "start",
      dragFree: true,
    })

    if (desktopDisplayAsCarousel) {
      next?.addEventListener("click", this.emblaNode.scrollNext, false)
      prev?.addEventListener("click", this.emblaNode.scrollPrev, false)

      const updateArrows = () => {
        next.disabled = !this.emblaNode.canScrollNext()
        prev.disabled = !this.emblaNode.canScrollPrev()
      }

      this.emblaNode.on("select", updateArrows)
      updateArrows()
    }
  },
  init() {
    if (desktopDisplayAsCarousel || isMobile) {
      this.initEmbla()
    }
    this.$watch("isMobile", value => {
      if (desktopDisplayAsCarousel) return
      if (value) {
        this.initEmbla()
      } else {
        this.emblaNode.destroy()
      }
    })
  },
})
