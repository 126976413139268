import { animateCountUp } from "../utils/countUpUtil"

export default () => ({
  animate() {
    Array.from(this.$refs.statsList.children).forEach((item: any, index) => {
      animateCountUp(item, index, 500)
    })
  },
  init() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.animate()
          observer.disconnect()
        }
      })
    })

    observer.observe(this.$el)
  },
})
