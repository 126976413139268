import "../css/app.css"
import Alpine from "alpinejs"
import collapse from "@alpinejs/collapse"
import focus from "@alpinejs/focus"
import intersect from "@alpinejs/intersect"
import mask from "@alpinejs/mask"

import modalVideo from "./components/modal-video"
import mobileCardCarousel from "./components/mobile-card-carousel"
import horizontalCarousel from "./components/horizontal-carousel"
import siteMenu from "./components/site-menu"
import artCanvas from "./components/art-canvas"
import gallery from "./components/gallery"
import verticalSlider from "./components/vertical-slider"
import animatedBackground from "./components/animated-background"
import boostedHero from "./components/boosted-hero"
import richTextField from "./components/rich-text-field"
import boostedStats from "./components/boosted-stats"
import awardsBlock from "./components/awards-block"
import videoThumb from "./components/video-thumb"
import maxLength from "./components/max-length"

import editProject from "./components/edit-project"
import coverMedia from "./components/edit-project/cover-media"
import croppingTool from "./components/edit-project/cropping-tool"
import videoLinkForm from "./components/edit-project/video-link-form"
import { formField, formGroupManager } from "./components/edit-project/fields"

// Alpine
window.Alpine = Alpine

// Components
Alpine.data("mobileCardCarousel", mobileCardCarousel)
Alpine.data("horizontalCarousel", horizontalCarousel)
Alpine.data("siteMenu", siteMenu)
Alpine.data("artCanvas", artCanvas)
Alpine.data("modalVideo", modalVideo)
Alpine.data("verticalSlider", verticalSlider)
Alpine.data("gallery", gallery)
Alpine.data("animatedBackground", animatedBackground)
Alpine.data("boostedHero", boostedHero)
Alpine.data("richTextField", richTextField)
Alpine.data("boostedStats", boostedStats)
Alpine.data("awardsBlock", awardsBlock)
Alpine.data("videoThumb", videoThumb)
Alpine.data("maxLength", maxLength)

Alpine.data("editProject", editProject)
Alpine.data("coverMedia", coverMedia)
Alpine.data("croppingTool", croppingTool)
Alpine.data("videoLinkForm", videoLinkForm)
Alpine.data("formField", formField)
Alpine.data("formGroupManager", formGroupManager)

Alpine.store("modal", {
  isOpen: false,
  contentEl: null,
  data: {},
  open(contentEl, data) {
    this.contentEl = contentEl
    this.isOpen = true
    this.data = data
  },
  close() {
    this.isOpen = false
    this.contentEl = null
    this.data = {}
  },
  lenis: null,
})

// Plugins
Alpine.plugin(collapse)
Alpine.plugin(focus)
Alpine.plugin(intersect)
Alpine.plugin(mask)

Alpine.start()
