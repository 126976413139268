import gsap from "gsap"
import { animateCountUp } from "../utils/countUpUtil"

export default isMobile => ({
  animate() {
    const header = this.$refs.header
    const list = this.$refs.list

    const listItems = isMobile
      ? list.querySelectorAll(".js-animated-block")
      : list.children

    gsap.set(header, { opacity: 0 })
    gsap.set(listItems, { y: "100%" })

    if (isMobile) {
      const textContentItems = list.querySelectorAll(".js-text-content")
      gsap.set(textContentItems, { opacity: 0 })
    }

    gsap.to(header, {
      opacity: 1,
      duration: 0.5,
      delay: 0.5,
    })

    Array.from(list.children).forEach((item: any, index) => {
      const textContentItem = item.querySelector(".js-text-content")

      const animatedItem = isMobile
        ? item.querySelector(".js-animated-block")
        : item

      gsap.to(animatedItem, {
        opacity: 1,
        y: 0,
        duration: 1.5,
        delay: 1 + index * 0.375,
        onStart: () => {
          animateCountUp(item, index, 0, isMobile)
        },
      })
      gsap.to(textContentItem, {
        opacity: 1,
        duration: 1,
        delay: 1 + index * 0.375,
      })
    })
  },
  init() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.animate()
          observer.disconnect()
        }
      })
    })

    observer.observe(this.$el)
  },
})
